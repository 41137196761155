<template>
  <el-dialog
    v-if="userShow"
    :title="title"
    :visible.sync="userShow"
    :modal-append-to-body="false"
    :before-close="closeDialog"
    top="9vh"
    width="70%">
    <ProcessUserDeptDialog v-if="!isMultiInstance && approveIds" ref="processUserDept" @row-data="getUser"
                           :approveIds="approveIds"></ProcessUserDeptDialog>
    <ProcessUserDeptSelectionDialog v-if="isMultiInstance && approveIds" @select-data="getUserSelection"
                                    :approveIds="approveIds"></ProcessUserDeptSelectionDialog>
    <UserDetpDialog
      v-if="!approveIds"
      ref="UserDeptMultipleDialog"
      @select-data="selectData"
      :deptId="deptId"
    ></UserDetpDialog>
    <div slot="footer" v-if="approveIds">
      <el-button @click="closeDialog">{{ $t("cip.cmn.btn.celBtn") }}</el-button>
      <el-button @click="setCurrent()"  v-if="!isMultiInstance">取消选择</el-button>
      <el-button @click="headConfirm" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import ProcessUserDeptDialog from "@/views/components/UserDeptDialog/processUserDeptDialog.vue";
import ProcessUserDeptSelectionDialog from "@/views/components/UserDeptDialog/processUserDeptSelectionDialog.vue";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";

export default {
  name: "processUserDialog",
  computed:{
    title(){
      return '指定' + this.nodeName +'审批人'
    },
  },
  components: {
    UserDetpDialog,
    ProcessUserDeptDialog,
    ProcessUserDeptSelectionDialog
  },
  data() {
    return {
      userIds: '',
      approveIds: '',
      nodeName: '',
      key: '',
      userShow: false,
      isMultiInstance: false,
      deptId:'',
    }
  },
  methods: {
    closeDialog() {
      this.userShow = false;
      this.$emit('closeProcess')
    },
    initDialog(row) {
      this.approveIds = row.approveIds
      this.isMultiInstance = row.isMultiInstance
      this.deptId = row.deptId;
      this.userIds = '';
      this.nodeName = row.nodeName || '';
      this.key = row.key;
      this.userShow = true
    },
    selectData(row) {
      this.userIds = row.id;
      this.userName = row.name;
      this.headConfirm()
    },
    getUserSelection(data,nameS) {
      this.userIds = data;
      this.userName = nameS;
    },
    getUser(data) {
      this.userIds = data.id;
      this.userName = data.name;
    },
    setCurrent(row) {
      this.$refs.processUserDept.$refs.crud.setCurrentRow(row);
      this.$refs.processUserDept.selectRow='';
      this.userIds='';
    },
    headConfirm() {
      if(this.userIds == '')return this.$message.error(`请选择${this.nodeName}审批人`)
        this.userShow = false
        this.$emit("getUserSelection", {[this.key]:this.userIds},this.key,this.userIds,this.userName)
      this.userIds='';
      this.key='';
      this.userName='';
    },
  },
  mounted() {
  },
}
</script>
