var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("avue-crud", {
    ref: "gridLayout",
    staticClass: "UserDeptCrud",
    attrs: {
      option: _vm.tableOptions,
      data: _vm.tableData,
      "table-loading": _vm.tableLoading,
    },
    on: { "selection-change": _vm.selectionChange, "row-click": _vm.rowClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }