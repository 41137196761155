var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userShow
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.userShow,
            "modal-append-to-body": false,
            "before-close": _vm.closeDialog,
            top: "9vh",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          !_vm.isMultiInstance && _vm.approveIds
            ? _c("ProcessUserDeptDialog", {
                ref: "processUserDept",
                attrs: { approveIds: _vm.approveIds },
                on: { "row-data": _vm.getUser },
              })
            : _vm._e(),
          _vm.isMultiInstance && _vm.approveIds
            ? _c("ProcessUserDeptSelectionDialog", {
                attrs: { approveIds: _vm.approveIds },
                on: { "select-data": _vm.getUserSelection },
              })
            : _vm._e(),
          !_vm.approveIds
            ? _c("UserDetpDialog", {
                ref: "UserDeptMultipleDialog",
                attrs: { deptId: _vm.deptId },
                on: { "select-data": _vm.selectData },
              })
            : _vm._e(),
          _vm.approveIds
            ? _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c("el-button", { on: { click: _vm.closeDialog } }, [
                    _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
                  ]),
                  !_vm.isMultiInstance
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.setCurrent()
                            },
                          },
                        },
                        [_vm._v("取消选择")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.headConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }