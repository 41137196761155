<template>
          <avue-crud ref="gridLayout" class="UserDeptCrud"
                       @selection-change="selectionChange"
                     @row-click="rowClick"
                       :option="tableOptions" :data="tableData"  :table-loading="tableLoading"
                >
          </avue-crud>
</template>

<script>
import {selectUserByIds,} from "@/api/system/user";
import {mapGetters} from "vuex";

export default {
  name: "user",
  components: {
  },
  props: {
    approveIds:{
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      selectionList: [],
      tableOptions: {
        height:400,
        customRowAddBtn: false,
        menu: false,
        border: true,
        stripe: true,
        addBtn: false,
        header: false,
        selection: true,
        column: [
          {
            label: "用户账号",
            prop: "account",
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            align: 'left',
            overHidden: true,
          },
          // {
          //   label: "主组织",
          //   prop: "masterOrganizationName",
          //   align: 'left',
          //   overHidden: true,
          // },
          // {
          //   label: "用户类型",
          //   prop: "personnelType",
          //   type: "select",
          //   width: 200,
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   align: 'left',
          //   dataType: 'string',
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type"
          // },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    nameS() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.onLoad(this.page)
  },
  methods: {
    selectionChange(list) {
      this.selectionList = list;
      this.$emit('select-data', this.ids,this.nameS);
    },
    rowClick(row){
      this.$refs.gridLayout.toggleSelection([row])
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      let formData=new FormData()
      formData.append('ids',this.approveIds)
      selectUserByIds(formData).then(res => {
        this.tableData = res.data.data;
        this.tableLoading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table--small td, .el-table--small th{
  line-height: 26px;
}
::v-deep .el-table .cell {
  line-height: 32px;
}
::v-deep .el-tag--small{
  display: none;
}
::v-deep .el-table--border {
  border-bottom: 1px solid #cccccc ;
}
</style>
