var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("avue-crud", {
    ref: "crud",
    attrs: {
      option: _vm.tableOptions,
      "table-loading": _vm.tableLoading,
      data: _vm.tableData,
    },
    on: { "row-click": _vm.currentRowChange, "on-load": _vm.onLoad },
    scopedSlots: _vm._u([
      {
        key: "radio",
        fn: function ({ row }) {
          return [
            _c(
              "el-radio",
              {
                attrs: { label: row.$index },
                model: {
                  value: _vm.selectRow,
                  callback: function ($$v) {
                    _vm.selectRow = $$v
                  },
                  expression: "selectRow",
                },
              },
              [_c("i")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }